import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TRANSLATED_LANGUAGES } from 'src/app/constants';

@Injectable({
  providedIn: 'root'
})
export class I18nService {
  private language$: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null);

  constructor(private translateService: TranslateService) {}

  initLanguages(): void {
    this.translateService.addLangs(
      TRANSLATED_LANGUAGES.map((languages) => languages.code)
    );

    let browserLanguage =
      localStorage.getItem('locale') ||
      this.translateService.getBrowserLang() ||
      'en';

    const supportedLangCodes = TRANSLATED_LANGUAGES.map((lang) => lang.code);

    // setting fallback lang code as 'en' & handle Norwegian language codes
    browserLanguage = supportedLangCodes.includes(browserLanguage)
      ? browserLanguage
      : browserLanguage === 'nb' || browserLanguage === 'nn'
        ? 'no'
        : 'en';

    localStorage.setItem('locale', browserLanguage);

    this.translateService.use(browserLanguage);
  }

  setLanguage(language: string = 'en'): void {
    this.language$.next(language);
    this.translateService.use(language);
    localStorage.setItem('locale', language);
  }

  getCurrentLanguage(): Observable<string | null> {
    return this.language$.asObservable();
  }
}
