import { createApi } from 'ngrx-rtk-query';
import { TRole } from 'src/app/modules/admin/roles/roles.types';
import { PageNumber } from 'src/app/shared/components/pagination/pagination.types';
import { Methods } from 'src/app/shared/types/enums';
import httpClientBaseQuery from '../../../baseQuery';

export const roleApi = createApi({
  reducerPath: 'roleApi',
  baseQuery: httpClientBaseQuery,
  tagTypes: ['Role'],
  endpoints: (build) => ({
    getRoles: build.query<TRole[], PageNumber>({
      query: ({ page = 1, limit }) => ({
        url: `/roles?sort=updated_at:desc${page ? `&page=${page}` : ''}${
          limit ? `&limit=${limit}` : ''
        }`
      })
    }),
    createRole: build.mutation<TRole, { data: TRole }>({
      query: ({ data }) => ({
        url: `/roles`,
        body: data,
        method: Methods.Post
      })
    }),
    updateRole: build.mutation<TRole, { data: TRole }>({
      query: ({ data }) => ({
        url: `/roles/${data.uid}`,
        body: data,
        method: Methods.Patch
      })
    })
  })
});

export const {
  useGetRolesQuery,
  useLazyGetRolesQuery,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  usePrefetch: useRolePrefetch,
  endpoints: roleApiEndpoints
} = roleApi;
