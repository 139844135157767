import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import {
  NgbCarouselModule,
  NgbDropdownModule
} from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { AlertComponent } from './components/alert/alert.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { BadgeComponent } from './components/badge/badge.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { ButtonComponent } from './components/button/button.component';
import { CardComponent } from './components/card/card.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { InputComponent } from './components/input/input.component';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';
import { ModalComponent } from './components/modal/modal.component';
import { OtpComponent } from './components/otp/otp.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PillComponent } from './components/pill/pill.component';
import { RadioComponent } from './components/radio/radio.component';
import { SelectComponent } from './components/select/select.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { SkeletonLoaderComponent } from './components/skeleton-loader/skeleton-loader.component';
import { SliderComponent } from './components/slider/slider.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SwitchComponent } from './components/switch/switch.component';
import { TableComponent } from './components/table/table.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { TopnavComponent } from './components/topnav/topnav.component';
import { SvgComponent } from './images/svg/svg.component';
import { ArrayMapPipe } from './pipes/array_map/array-map.pipe';
import { FormErrorPipe } from './pipes/form-error/form-error.pipe';
import { FormatTimePipe } from './pipes/format-time/format-time.pipe';
import { NameSignaturePipe } from './pipes/name-signature/name-signature.pipe';
import { TestRevisionFilterPipe } from './pipes/test-revision-filter/test-revision-filter.pipe';
import { TypeofPipe } from './pipes/typeof/typeof.pipe';
import { ActionTemplateComponent } from './templates/action-template/action-template.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxSliderModule } from 'ngx-slider-v2';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    ButtonComponent,
    AlertComponent,
    RadioComponent,
    CheckboxComponent,
    SwitchComponent,
    InputComponent,
    TextareaComponent,
    BreadcrumbsComponent,
    PageTitleComponent,
    SpinnerComponent,
    BadgeComponent,
    AvatarComponent,
    NameSignaturePipe,
    ArrayMapPipe,
    PaginationComponent,
    PillComponent,
    TableComponent,
    ActionTemplateComponent,
    ModalComponent,
    TypeofPipe,
    SvgComponent,
    CardComponent,
    SelectComponent,
    SidenavComponent,
    TopnavComponent,
    SliderComponent,
    SkeletonLoaderComponent,
    FormErrorPipe,
    TestRevisionFilterPipe,
    FormatTimePipe,
    OtpComponent,
    LanguageSwitcherComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    NgbDropdownModule,
    NgbCarouselModule,
    NgxSliderModule,
    LottieModule.forRoot({ player: playerFactory }),
    ToastrModule.forRoot({
      preventDuplicates: true
    }),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false,
      extend: true
    })
  ],
  exports: [
    ButtonComponent,
    AlertComponent,
    CheckboxComponent,
    RadioComponent,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    SwitchComponent,
    InputComponent,
    TextareaComponent,
    BreadcrumbsComponent,
    PageTitleComponent,
    BadgeComponent,
    AvatarComponent,
    NameSignaturePipe,
    PillComponent,
    PaginationComponent,
    TableComponent,
    ActionTemplateComponent,
    ModalComponent,
    TypeofPipe,
    SvgComponent,
    CardComponent,
    SpinnerComponent,
    SelectComponent,
    SidenavComponent,
    TopnavComponent,
    SliderComponent,
    HttpClientModule,
    RouterModule,
    SkeletonLoaderComponent,
    ArrayMapPipe,
    FormErrorPipe,
    TestRevisionFilterPipe,
    FormatTimePipe,
    ToastrModule,
    OtpComponent,
    LanguageSwitcherComponent,
    NgbDropdownModule,
    NgbCarouselModule,
    NgxSkeletonLoaderModule,
    NgxSliderModule,
    LottieModule
  ],
  providers: []
})
export class SharedModule {}
