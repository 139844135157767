export const environment = {
  production: true,
  sentryEnvironment: 'production',
  apiUrl: 'https://api.cohrex.ai/api/v1',
  cryptoSecretKey: '28698061-0b46-1d96-c0de-336cdbdb0fa5',
  appBaseUrl: 'https://app.cohrex.ai',
  enableSentry: true,
  sentryDSN:
    'https://23298173d64fa85d6eadc1d77533ef55@o4507304832991232.ingest.de.sentry.io/4507304838037584',
  sentryReplaysSessionSampleRate: 1.0,
  supportEmail: 'support@cohrgroup.com'
};
