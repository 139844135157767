import {
  EnvironmentProviders,
  isDevMode,
  makeEnvironmentProviders
} from '@angular/core';
import {
  DEFAULT_ROUTER_FEATURENAME,
  RouterReducerState,
  RouterState,
  provideRouterStore,
  routerReducer
} from '@ngrx/router-store';
import { ActionReducerMap, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideStoreRtkQuery } from 'ngrx-rtk-query';
import { authApi } from './api/cohrex/endpoints/auth';
import { customerApi } from './api/cohrex/endpoints/customers';
import { dashboardApi } from './api/cohrex/endpoints/dashboard';
import { mediaApi } from './api/cohrex/endpoints/media';
import { profileApi } from './api/cohrex/endpoints/profiles';
import { roleApi } from './api/cohrex/endpoints/roles';
import { tenantApi } from './api/cohrex/endpoints/tenants';
import { testApi } from './api/cohrex/endpoints/tests';
import { userApi } from './api/cohrex/endpoints/users';

export type RootState = {
  [DEFAULT_ROUTER_FEATURENAME]: RouterReducerState;
  [authApi.reducerPath]: ReturnType<typeof authApi.reducer>;
  [tenantApi.reducerPath]: ReturnType<typeof tenantApi.reducer>;
  [customerApi.reducerPath]: ReturnType<typeof customerApi.reducer>;
  [roleApi.reducerPath]: ReturnType<typeof roleApi.reducer>;
  [userApi.reducerPath]: ReturnType<typeof userApi.reducer>;
  [profileApi.reducerPath]: ReturnType<typeof profileApi.reducer>;
  [testApi.reducerPath]: ReturnType<typeof testApi.reducer>;
  [mediaApi.reducerPath]: ReturnType<typeof mediaApi.reducer>;
  [dashboardApi.reducerPath]: ReturnType<typeof dashboardApi.reducer>;
  // Rest of Queries are lazy / code splitted
};

export const reducers: ActionReducerMap<RootState> = {
  [DEFAULT_ROUTER_FEATURENAME]: routerReducer,
  [authApi.reducerPath]: authApi.reducer,
  [tenantApi.reducerPath]: tenantApi.reducer,
  [customerApi.reducerPath]: customerApi.reducer,
  [roleApi.reducerPath]: roleApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [testApi.reducerPath]: testApi.reducer,
  [mediaApi.reducerPath]: mediaApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer
  // Rest of Queries are lazy / code splitted
};

export const provideCoreStore = (): EnvironmentProviders => {
  return makeEnvironmentProviders([
    provideStore(reducers, {
      metaReducers: [
        authApi.metareducer,
        tenantApi.metareducer,
        customerApi.metareducer,
        roleApi.metareducer,
        userApi.metareducer,
        profileApi.metareducer,
        testApi.metareducer,
        mediaApi.metareducer
      ]
    }),
    provideRouterStore({ routerState: RouterState.Minimal }),
    provideStoreDevtools({ logOnly: !isDevMode(), name: 'BMED Agenda App' }),
    provideStoreRtkQuery({ setupListeners: true })
  ]);
};
