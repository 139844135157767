import { createApi } from 'ngrx-rtk-query';
import { Methods } from 'src/app/shared/types/enums';
import httpClientBaseQuery from '../../../baseQuery';

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: httpClientBaseQuery,
  tagTypes: ['Dashboard'],
  endpoints: (build) => ({
    getDashboardData: build.query({
      query: ({ data: body }) => ({
        url: `/dashboard`,
        body,
        method: Methods.Get
      })
    })
  })
});

export const {
  useLazyGetDashboardDataQuery,
  usePrefetch: useGetDashboardDataPrefetch,
  endpoints: dashboardApiEndpoints
} = dashboardApi;
