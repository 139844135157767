import { createApi } from 'ngrx-rtk-query';
import httpClientBaseQuery from '../../../baseQuery';
import { Methods } from 'src/app/shared/types/enums';
import { TUser } from 'src/app/shared/types';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: httpClientBaseQuery,
  tagTypes: ['User'],
  endpoints: (build) => ({
    createUser: build.mutation<TUser, { data: TUser }>({
      query: ({ data }) => ({
        url: `/users`,
        body: data,
        method: Methods.Post
      })
    }),
    updateUser: build.mutation<TUser, { data: TUser }>({
      query: ({ data }) => ({
        url: `/users/${data.uid}`,
        body: data,
        method: Methods.Patch
      })
    })
  })
});

export const {
  useCreateUserMutation,
  useUpdateUserMutation,
  usePrefetch: useUserPrefetch,
  endpoints: userApiEndpoints
} = userApi;
