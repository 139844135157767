import { createApi } from 'ngrx-rtk-query';
import {
  TTestAnswer,
  TTestMeta,
  TTestProgress,
  TTestQuestion
} from '../../../../../modules/tests/types';
import { TInvite, TTest } from '../../../../../shared/types';
import { Methods } from '../../../../../shared/types/enums';
import httpClientBaseQuery from '../../../baseQuery';

export const testApi = createApi({
  reducerPath: 'testApi',
  baseQuery: httpClientBaseQuery,
  tagTypes: ['Test'],
  endpoints: (build) => ({
    getTests: build.query<TTest, void>({
      query: () => ({
        url: `/tests?include=revisions`
      })
    }),
    getAvailableInvites: build.query<
      { message: string; data: TInvite[] },
      void
    >({
      query: () => ({
        url: `/tests/available`
      })
    }),
    initiateTest: build.mutation<
      { data: TTestMeta },
      { invite_test_uid: string }
    >({
      query: (data) => ({
        url: `/tests/initiate`,
        body: data,
        method: Methods.Post
      })
    }),
    startTest: build.mutation<{ data: { progress: TTestProgress } }, {}>({
      query: (data) => ({
        url: `/tests/start`,
        body: data,
        method: Methods.Post
      })
    }),
    startTestPart: build.mutation<{ data: { progress: TTestProgress } }, {}>({
      query: (data) => ({
        url: `/tests/part/start`,
        body: data,
        method: Methods.Post
      })
    }),
    getQuestion: build.query<{ message: string; data: TTestQuestion }, void>({
      query: () => ({
        url: `/tests/get/question`
      })
    }),

    answerQuestion: build.mutation<
      { data: { correct: boolean } },
      { data: TTestAnswer }
    >({
      query: ({ data }) => {
        return {
          url: `/tests/answer/${data.uid}`,
          body: data,
          method: Methods.Post
        };
      }
    })
  })
});

export const {
  useGetTestsQuery,
  useLazyGetAvailableInvitesQuery,
  useInitiateTestMutation,
  useStartTestMutation,
  useStartTestPartMutation,
  useLazyGetQuestionQuery,
  useAnswerQuestionMutation,
  usePrefetch: useTestsPrefetch,
  endpoints: testApiEndpoints
} = testApi;
