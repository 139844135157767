import { createApi } from 'ngrx-rtk-query';
import { TTenant, Tenant } from 'src/app/modules/admin/tenants/tenants.types';
import { Methods } from 'src/app/shared/types/enums';
import httpClientBaseQuery from '../../../baseQuery';

export const tenantApi = createApi({
  reducerPath: 'tenantApi',
  baseQuery: httpClientBaseQuery,
  tagTypes: ['Tenant'],
  endpoints: (build) => ({
    getTenants: build.query<Tenant, void>({
      query: () => ({
        url: `/tenants`
      })
    }),
    createTenant: build.mutation<TTenant, { data: TTenant }>({
      query: ({ data }) => ({
        url: `/tenants`,
        body: data,
        method: Methods.Post
      })
    }),
    updateTenant: build.mutation<TTenant, { data: TTenant }>({
      query: ({ data }) => ({
        url: `/tenants/${data.uid}`,
        body: data,
        method: Methods.Patch
      })
    })
  })
});

export const {
  useGetTenantsQuery,
  useLazyGetTenantsQuery,
  useCreateTenantMutation,
  useUpdateTenantMutation,
  usePrefetch: useTenantPrefetch,
  endpoints: tenantApiEndpoints
} = tenantApi;
