import { createApi } from 'ngrx-rtk-query';
import {
  Media,
  MediaQuery
} from 'src/app/modules/admin/media-archives/media-archives.types';
import { PageNumber } from 'src/app/shared/components/pagination/pagination.types';
import { Methods } from 'src/app/shared/types/enums';
import httpClientBaseQuery from '../../../baseQuery';

export const mediaApi = createApi({
  reducerPath: 'mediaApi',
  baseQuery: httpClientBaseQuery,
  tagTypes: ['Media'],
  endpoints: (build) => ({
    getMedia: build.query<{ data: Media }, MediaQuery & PageNumber>({
      query: ({ page, name, tag }) => ({
        url: `/media?${name ? `&name=${name}` : ''}${
          page ? `&page=${page}` : ''
        }${tag ? `&tag=${tag}` : ''}`
      })
    }),
    uploadFile: build.mutation<{ data: Media }, { data: any }>({
      query: ({ data }) => ({
        url: `/media`,
        body: data,
        method: Methods.Post,
        isFormData: true
      })
    }),
    uploadCSVFile: build.mutation<{ data: Media }, { data: any }>({
      query: ({ data }) => ({
        url: `/import`,
        body: data,
        method: Methods.Post,
        isFormData: true
      })
    })
  })
});

export const {
  useLazyGetMediaQuery,
  useUploadFileMutation,
  useUploadCSVFileMutation,
  usePrefetch: useMediaPrefetch,
  endpoints: mediaApiEndpoints
} = mediaApi;
