import { createApi } from 'ngrx-rtk-query';
import { TProfile } from 'src/app/modules/admin/profiles/profiles.types';
import { PageNumber } from 'src/app/shared/components/pagination/pagination.types';
import { Methods } from 'src/app/shared/types/enums';
import httpClientBaseQuery from '../../../baseQuery';

export const profileApi = createApi({
  reducerPath: 'profileApi',
  baseQuery: httpClientBaseQuery,
  tagTypes: ['Profile'],
  endpoints: (build) => ({
    getProfiles: build.query<TProfile, PageNumber>({
      query: ({ page, limit }) => ({
        url: `/profiles?sort=updated_at:desc&include=tests.revisions${
          page ? `&page=${page}` : ''
        }${limit ? `&limit=${limit}` : ''}`
      })
    }),
    createProfile: build.mutation<TProfile, { data: TProfile }>({
      query: ({ data }) => ({
        url: `/profiles`,
        body: data,
        method: Methods.Post
      })
    }),
    updateProfile: build.mutation<TProfile, { data: TProfile }>({
      query: ({ data }) => ({
        url: `/profiles/${data.uid}`,
        body: data,
        method: Methods.Patch
      })
    })
  })
});

export const {
  useGetProfilesQuery,
  useLazyGetProfilesQuery,
  useCreateProfileMutation,
  useUpdateProfileMutation,
  usePrefetch: useProfilePrefetch,
  endpoints: projectsApiEndpoints
} = profileApi;
