export enum RequestMethods {
  Get = 'GET',
  Post = 'POST',
  Patch = 'PATCH'
}

export enum ColorsEnum {
  Primary = 'primary',
  Warning = 'warning',
  Success = 'success',
  Error = 'error'
}

export enum Methods {
  Post = 'POST',
  Get = 'GET',
  Delete = 'DELETE',
  Patch = 'PATCH',
  Put = 'PUT'
}

export enum Actions {
  Edit = 'edit',
  Delete = 'delete'
}

export enum ErrorStatuses {
  Unauthorized = 401,
  BadRequest = 400,
  Forbidden = 403,
  NotFound = 404,
  MethodNotAllowed = 405,
  RequestTimeout = 408,
  Conflict = 409,
  Gone = 410,
  InternalServerError = 500,
  NotImplemented = 501,
  BadGateway = 502,
  ServiceUnavailable = 503,
  GatewayTimeout = 504
}

export enum ModalTypes {
  Add = 'Add',
  Edit = 'Edit'
}

export enum QueryStatuses {
  Fulfilled = 'fulfilled',
  Pending = 'pending'
}

export enum AlertMessage {
  Error = 'error',
  Success = 'success'
}

export enum AlertAction {
  Added = 'added',
  Updated = 'updated'
}

export enum UserRoles {
  SuperAdmin = 'superadmin',
  Administrator = 'administrator',
  TestTaker = 'testtaker'
}

export enum TestStatus {
  Completed = 'completed',
  NotStarted = 'not_started',
  NotCompleted = 'not_completed'
}

export enum NormValueGroups {
  ExecutiveFunction = 'executive_functions',
  Analytics = 'analytics'
}

export enum TestThresholds {
  VeryHigh = 'test_takers.statistics.very_high',
  High = 'test_takers.statistics.high',
  Average = 'test_takers.statistics.average',
  Moderate = 'test_takers.statistics.moderate',
  Low = 'test_takers.statistics.low'
}

export enum TTestTypes {
  Uknown = 'Uknown',
  NumericalReasoning = 'NumericalReasoning',
  LogicalReasoning = 'LogicalReasoning',
  VerbalReasoning = 'VerbalReasoning',
  Personality = 'Personality',
  ExecutiveFunctions = 'ExecutiveFunctions',
  Analytics = 'Analytics'
}
