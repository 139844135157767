import { createApi } from 'ngrx-rtk-query';
import { TCustomer } from 'src/app/modules/admin/customers/customers.types';
import { KeyType, TInvite, TUser } from 'src/app/shared/types';
import { Methods } from 'src/app/shared/types/enums';
import httpClientBaseQuery from '../../../baseQuery';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: httpClientBaseQuery,
  tagTypes: ['Auth'],
  endpoints: (build) => ({
    login: build.mutation<{ data: TUser & TCustomer[] }, { data: KeyType }>({
      query: ({ data: body }) => ({
        url: `/sign-in`,
        body,
        authorizationRequired: false,
        method: Methods.Post
      })
    }),
    verifyOTP: build.mutation<
      { data: TUser & TCustomer[] },
      { data: KeyType; inviteId: string }
    >({
      query: ({ data: body, inviteId }) => ({
        url: `/invite/${inviteId}/verify-otp`,
        body,
        authorizationRequired: false,
        method: Methods.Post
      })
    }),
    forgotPassword: build.mutation<KeyType, { data: KeyType }>({
      query: ({ data: body }) => ({
        url: `/forgot-password`,
        body,
        authorizationRequired: false,
        method: Methods.Post
      })
    }),
    logout: build.mutation({
      query: () => ({
        url: `/sign-out`,
        method: Methods.Post
      })
    }),
    resetPassword: build.mutation<KeyType, { data: KeyType }>({
      query: ({ data: body }) => ({
        url: `/reset-password`,
        authorizationRequired: false,
        method: Methods.Post,
        body
      })
    }),
    verifyResetToken: build.mutation<KeyType, { data: KeyType }>({
      query: ({ data: body }) => ({
        url: `/verify-reset-token`,
        method: Methods.Post,
        body,
        authorizationRequired: false
      })
    }),
    sendInviteOTP: build.mutation<
      { data: TInvite },
      { inviteId: string; data: KeyType }
    >({
      query: ({ inviteId, data: body }) => ({
        url: `/invite/${inviteId}/send-otp`,
        authorizationRequired: false,
        method: Methods.Post,
        body
      })
    }),
    verifyInviteEmail: build.mutation<{ data: TInvite }, { data: KeyType }>({
      query: ({ data: body }) => ({
        url: `/invite/verify-email`,
        authorizationRequired: false,
        method: Methods.Post,
        body
      })
    }),
    markInstructionsAsViewed: build.mutation<
      { data: TInvite },
      { inviteId: string }
    >({
      query: ({ inviteId }) => ({
        url: `/invite/${inviteId}/viewed-instructions`,
        method: Methods.Post,
        authorizationRequired: false
      })
    })
  })
});

export const {
  useLoginMutation,
  useVerifyOTPMutation,
  useLogoutMutation,
  useVerifyInviteEmailMutation,
  useSendInviteOTPMutation,
  useForgotPasswordMutation,
  useMarkInstructionsAsViewedMutation,
  useResetPasswordMutation,
  usePrefetch: useAuthPrefetch,
  useVerifyResetTokenMutation,
  endpoints: authApiEndpoints
} = authApi;
