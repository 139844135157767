import { createApi } from 'ngrx-rtk-query';
import {
  TExport,
  TTestTaker
} from 'src/app/modules/admin/customers/customer-test-takers/test-taker.types';
import {
  Customer,
  TCustomer
} from 'src/app/modules/admin/customers/customers.types';
import { TProfile } from 'src/app/modules/admin/profiles/profiles.types';
import {
  TProject,
  TProjectTestTakers
} from 'src/app/modules/admin/projects/projects.types';
import { PageNumber } from 'src/app/shared/components/pagination/pagination.types';
import { ActivityLog } from 'src/app/shared/types';
import { Methods } from 'src/app/shared/types/enums';
import httpClientBaseQuery from '../../../baseQuery';

export const customerApi = createApi({
  reducerPath: 'customerApi',
  baseQuery: httpClientBaseQuery,
  tagTypes: ['Customer'],
  endpoints: (build) => ({
    getCustomers: build.query<Customer, PageNumber>({
      query: ({ page }) => ({
        url: `/customers?count=customerTests&sort=updated_at:desc${
          page ? `&page=${page}` : ''
        }`
      })
    }),
    createCustomer: build.mutation<TCustomer, { data: TCustomer }>({
      query: ({ data }) => ({
        url: `/customers`,
        body: data,
        method: Methods.Post
      })
    }),
    updateCustomer: build.mutation<TCustomer, { data: TCustomer }>({
      query: ({ data }) => ({
        url: `/customers/${data.uid}`,
        body: data,
        method: Methods.Patch
      })
    }),
    getCustomerUsers: build.query<TCustomer, { id: string }>({
      query: ({ id }) => ({
        url: `/customers/${id}?include=users.roles`
      })
    }),
    getCustomerProjects: build.query<
      { data: TProject[] },
      { id: string } & PageNumber
    >({
      query: ({ id, page, limit }) => ({
        url: `/projects?sort=id:desc&customer_id_eq=${id}&include=profile,customer,tests.revisions${
          page ? `&page=${page}` : ''
        }${limit ? `&limit=${limit}` : ''}`
      })
    }),
    getCustomerTestTakers: build.query<
      TCustomer,
      { id: string; projectId?: string } & PageNumber
    >({
      query: ({ id, page, projectId }) => ({
        url: `/${id}/test-takers${projectId ? `/project/${projectId}` : ''}?${
          page ? `&page=${page}` : ''
        }&sort=id:desc`
      })
    }),
    getCustomerTestTaker: build.query<
      { data: TTestTaker },
      { testTakerId: string }
    >({
      query: ({ testTakerId }) => ({
        url: `/test-takers/${testTakerId}`
      })
    }),
    getCustomerTestTakerActivityLogs: build.query<
      { data: { data: ActivityLog[] } },
      { testTakerId: string; page?: number }
    >({
      query: ({ testTakerId, page }) => ({
        url: `/test-takers/${testTakerId}/activity-logs?page=${page}`
      })
    }),
    getCustomerProfiles: build.query<TCustomer, { id: string }>({
      query: ({ id }) => ({
        url: `/customers/${id}/profiles`
      })
    }),
    getCustomerProjectTestTakers: build.query<
      TProjectTestTakers,
      { id: string; projectId: string }
    >({
      query: ({ id, projectId }) => ({
        url: `/customers/${id}/project/${projectId}`
      })
    }),
    getCustomerProfile: build.query<
      { data: TProfile },
      { id: string; profileId: string }
    >({
      query: ({ id: customerId, profileId }) => ({
        url: `/customers/${customerId}/profiles/${profileId}`
      })
    }),
    exportTestTaker: build.query<{ data: TExport }, { inviteUid: string }>({
      query: ({ inviteUid }) => ({
        url: `/test-takers/${inviteUid}/export`
      })
    }),
    exportTestTakerAnswers: build.query<
      { data: TExport },
      { inviteUid: string }
    >({
      query: ({ inviteUid }) => ({
        url: `/test-takers/${inviteUid}/export/answers`
      })
    }),
    exportTestTakersAnswers: build.query<{ data: TExport }, {}>({
      query: () => ({
        url: `/test-takers/export/answers`
      })
    }),
    exportTestTakers: build.query<{ data: TExport }, void>({
      query: () => ({
        url: `/test-takers/export`
      })
    }),
    createCustomerProject: build.mutation<TCustomer, { data: TProject }>({
      query: ({ data }) => ({
        url: `/customers/${data.customer_id}/project`,
        body: data,
        method: Methods.Post
      })
    }),
    assignCustomerProfile: build.mutation<
      { data: TCustomer },
      { data: TProject }
    >({
      query: ({ data }) => ({
        url: `/customers/${data.customer_id}/assign-profiles`,
        body: data,
        method: Methods.Post
      })
    }),
    seedCustomerData: build.mutation<
      TCustomer,
      { data: { customer_id: string } }
    >({
      query: ({ data }) => ({
        url: `/customers/${data.customer_id}/seed-data`,
        method: Methods.Post
      })
    }),
    updateCustomerProject: build.mutation<TCustomer, { data: TProject }>({
      query: ({ data }) => ({
        url: `/customers/${data.customer_id}/project/${data.uid}`,
        body: data,
        method: Methods.Patch
      })
    })
  })
});

export const {
  useGetCustomersQuery,
  useLazyGetCustomersQuery,
  useGetCustomerUsersQuery,
  useLazyGetCustomerUsersQuery,
  useGetCustomerProjectsQuery,
  useLazyGetCustomerProjectsQuery,
  useLazyGetCustomerTestTakersQuery,
  useLazyGetCustomerTestTakerQuery,
  useLazyGetCustomerTestTakerActivityLogsQuery,
  useLazyExportTestTakerQuery,
  useLazyExportTestTakerAnswersQuery,
  useLazyExportTestTakersAnswersQuery,
  useLazyExportTestTakersQuery,
  useLazyGetCustomerProfilesQuery,
  useLazyGetCustomerProfileQuery,
  useLazyGetCustomerProjectTestTakersQuery,
  useCreateCustomerProjectMutation,
  useAssignCustomerProfileMutation,
  useCreateCustomerMutation,
  useUpdateCustomerMutation,
  useSeedCustomerDataMutation,
  useUpdateCustomerProjectMutation,
  usePrefetch: usecustomerPrefetch,
  endpoints: customerApiEndpoints
} = customerApi;
